import "./autoreg.js"

$(document).ready(function () {
  //   $("#sql-text").prependTo($(".sql-text"))
  // $('article h1').appendTo($('.for-h'));

  // mobile burger menu
  $(".burger").on("click", function () {
    $(this).toggleClass("burger_active")
    $(".header__navigation").slideToggle()
    $(".header__buttons").removeClass("active")
  })

  $(".mob-menu-toggle").on("click", function () {
    $(this).toggleClass("menu_active")
    $(".header__navigation-menu--mobile").slideToggle()
  })

  if ($("li").has("ul")) {
    console.log("yessss")
  }

  // $("li").has("ul") &&
  $("li").has("ul").append('<span class="menu-down"></span>'),
    window.matchMedia("(max-width: 768px)").matches &&
      $(".menu-down").on("click", function () {
        $(this).parent().find($(".sub-menu")).slideToggle("fast")
      })

  if (window.matchMedia("(max-width: 768px)").matches) {
    $(".content h1").prependTo($("aside"))
  }

  $(window).on("click", function (event) {
    if ($(event.target).hasClass("header__btn-mob-icon")) {
      $(".header__buttons").toggleClass("active")
      $(".burger").removeClass("burger_active")
      if ($(window).innerWidth() < 768) {
        $(".header__navigation").slideUp("fast")
      }
    }
  })

  $(".slider").slick({
    dots: true,
    infinite: true,
    speed: 300,
    arrows: false,
    autoplay: true,
  })
  // onlajn-kazino-pin-uptest
  $(".faq").on("click", function (e) {
    const $item = $(e.target).closest(".faq-item")

    if (!$item.length) return false

    if ($item.hasClass("active")) {
      $item.removeClass("active")
      $item.find(".faq-item__body").slideUp()
      return false
    }

    $(this)
      .find(".faq-item")
      .each(function () {
        $(this).removeClass("active")
        $(this).find(".faq-item__body").slideUp()
      })

    $item.toggleClass("active")
    $item.find(".faq-item__body").slideToggle()
  })

  // if ($(".rate")) {
  // $(".average-ratings").appendTo($(".rate"))
  // } else {
  //   $(".average-ratings").hide()
  // }

  $("img.lazy").lazy({
    effect: "fadeIn",
    beforeLoad: function (element) {
      colorPicker(element)
    },
  })

  $(".comment-form-author label").text("Imię")
  //comments avatar
  $(".comment").each(function () {
    const author = $(this).find(".author").text(),
      name = author.split(" ")
    let result = ""
    for (let x = 0; x < name.length; x++) {
      if (x == 2) {
        break
      } else {
        result += name[x].charAt(0)
      }
    }
    $(this).find(".comment-avatar")[0].innerHTML = result
  })

  //COLOR PICKER

  //finds background color of a images
  function rgbToHex(r, g, b) {
    return ((r << 16) | (g << 8) | b).toString(16)
  }

  /**
   * Takes color from the image and fills the background of the parent
   *
   * @param {string} elem - picture
   */
  function colorPicker(elem) {
    let newImg = new Image()
    let canvas = elem.parents(".logo-bookmaker").find(".canvas_picker").get(0)
    let ctx = canvas.getContext("2d")

    if (elem.hasClass("lazy")) {
      newImg.src = $(elem).attr("data-src")
    } else if ($(elem).attr("data-lazy")) {
      newImg.src = $(elem).attr("data-lazy")
    } else {
      newImg.src = elem.src
    }

    newImg.onload = function () {
      ctx.drawImage(newImg, 0, 0)

      let dataImg = (dataImg = ctx.getImageData(1, 1, 1, 1).data)
      let hex =
        "#" +
        ("000000" + rgbToHex(dataImg[0], dataImg[1], dataImg[2])).slice(-6)

      elem.parents(".logo-bookmaker").css("backgroundColor", hex)
      // elem.parents(".logo-bookmaker").find(".card__logo-btn").css({
      //   borderColor: hex,
      //   color: hex,
      // })
    }
  }

  // $('.offers__card-logo-link img')
  // colorPicker(".offers__card-logo-link img")
})
